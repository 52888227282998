import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuthResponse } from '../../../model/api/auth.model';
import { isSessionStorageAvailable } from '../../../../share/utils/functions';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccessTokenService {
  private readonly TOKEN_KEY = 'accessToken';

  constructor(private http: HttpClient) {}

  get hasToken() {
    return this.retrieveToken() != null;
  }

  storeToken(token: string): void {
    if (isSessionStorageAvailable()) {
      sessionStorage.setItem(this.TOKEN_KEY, token);
    }
  }

  retrieveToken(): string | null {
    if (isSessionStorageAvailable()) {
      return sessionStorage.getItem(this.TOKEN_KEY);
    }
    return null;
  }

  removeToken(): void {
    if (isSessionStorageAvailable()) {
      sessionStorage.removeItem(this.TOKEN_KEY);
    }
  }

  refresh(refreshToken: string): Observable<IAuthResponse> {
    return this.http
      .post<IAuthResponse>('/refresh-token', {
        refreshToken: refreshToken,
      })
      .pipe(
        map((authResponse: IAuthResponse) => {
          if (authResponse.id_token) {
            this.storeToken(authResponse.id_token);
          }
          return authResponse;
        })
      );
  }
}
